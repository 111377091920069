<template>
  <b-sidebar
    id="add-new-sidebar"
    :visible="isAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ titulo }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <validation-provider
            #default="validationContext"
            name="Nome do Projeto"
            rules="required"
          >
            <b-form-group
              label="Nome do Projeto"
              label-for="nome-projeto"
            >
              <b-form-input
                id="nome-projeto"
                v-model="itemData.descricao"
                name="projeto"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Informe o nome do projeto"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Ano do Projeto"
            rules="required|integer|digits:4|between:2000,2050"
          >
            <b-form-group
              label="Ano do Projeto"
              label-for="ano-projeto"
            >
              <b-form-input
                id="ano-projeto"
                v-model="itemData.ano"
                name="ano"
                maxlength="4"
                :state="getValidationState(validationContext)"
                trim
                placeholder="Informe o ano do projeto"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Data Entrega"
            rules="required"
          >
            <b-form-group
              label="Data Entrega"
              label-for="data_entrega"
            >
              <b-form-input
                id="data_entrega"
                v-model="itemData.data_entrega"
                type="date"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Formato"
            rules="required"
          >
            <b-form-group
              label="Formato"
              label-for="formato"
            >
              <select-com-pesquisa
                id="formato"
                ref="responsaveis"
                v-model="itemData.formato"
                :url="`/projeto-formatos`"
                placeholder="Escolha o formato"
                :multiple="false"
                :disabled="false"
                :itens-para-edicao="[]"
                @selecionados="tratarEventoSelecionarFormato"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="Empresa"
            rules="required"
          >
            <b-form-group
              label="Empresa"
              label-for="empresa"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="empresa"
                v-model="itemData.empresa"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="listaEmpresas"
                label="nome"
                :reduce="val => val.uuid"
                :clearable="false"
                input-id="user-empresa"
                placeholder="Escolha uma empresa"
                multiple
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Responsável"
            rules="required"
          >
            <b-form-group
              label="Responsáveis"
              label-for="responsaveis"
              :state="getValidationState(validationContext)"
            >
              <select-com-pesquisa
                id="responsaveis"
                ref="responsaveis"
                v-model="itemData.responsaveis"
                :url="`/users?role=Líder`"
                placeholder="Escolha os responsáveis"
                :multiple="true"
                :disabled="false"
                :itens-para-edicao="[]"
                @selecionados="tratarEventoResponsaveisSelecionados"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Times"
            rules="required"
          >
            <b-form-group
              label="Times"
              label-for="times"
              :state="getValidationState(validationContext)"
            >
              <select-com-pesquisa
                id="times"
                ref="times"
                v-model="itemData.times"
                :url="`/times`"
                placeholder="Escolha os times"
                :multiple="true"
                :disabled="false"
                :itens-para-edicao="[]"
                @selecionados="tratarEventoTimesSelecionados"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group
            label="Escolha o cliente"
            label-for="cliente"
          >
            <select-com-pesquisa
              id="cliente"
              url="/clientes"
              placeholder="Escolha o cliente"
              :multiple="false"
              :itens-para-edicao="[]"
              @selecionados="tratarEventoClienteSelecionados"
            />

          </b-form-group>
          <b-form-group
            label="Escolha as disciplinas"
            label-for="disciplinas"
          >
            <select-com-pesquisa
              id="disciplinas"
              url="/disciplinas"
              placeholder="Escolha as disciplinas"
              :multiple="true"
              :itens-para-edicao="[]"
              @selecionados="tratarEventoDisciplinasSelecionadas"
            />

          </b-form-group>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="success"
              class="mr-2"
              type="submit"
            >
              Salvar
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSidebar,
} from 'bootstrap-vue'
import { onUnmounted, ref } from '@vue/composition-api'
import { required } from '@validations'
import { useToast } from 'vue-toastification/composition'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import projetosStoreModule from '@/views/apps/projetos/projetosStoreModule.js'
import Ripple from 'vue-ripple-directive'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    SelectComPesquisa,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewSidebarActive',
    event: 'update:is-add-new-sidebar-active',
  },
  props: {
    isAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    titulo: {
      type: String,
      required: true,
    },
    listaEmpresas: {
      type: Array,
      required: true,
      default: null,
    },

  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      descricao: '',
      times: null,
      responsaveis: null,
      empresa: null,
      data_entrega: null,
      ano: null,
      cliente: null,
    }

    const PROJETOS_APP_STORE_MODULE_NAME = 'app-projetos'

    // Register module
    if (!store.hasModule(PROJETOS_APP_STORE_MODULE_NAME)) store.registerModule(PROJETOS_APP_STORE_MODULE_NAME, projetosStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROJETOS_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJETOS_APP_STORE_MODULE_NAME)
    })
    const toast = useToast()

    const itemData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-projetos/store', itemData.value)
        .then(respo => {
          emit('refetch-data')
          emit('update:is-add-new-sidebar-active', false)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Projeto adicionado com sucesso',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error.response && error.response.status === 422) {
            const listaErros = error.response.data.errors
            for (const field in listaErros) {
              if (listaErros.hasOwnProperty(field)) {
                const errorMessages = listaErros[field]
                errorMessages.forEach(errorMessage => {
                  toast({
                    component: ToastificationContent,
                    props: {
                      title: errorMessage,
                      icon: 'AlertCircleIcon',
                      variant: 'danger',
                    },
                  })
                })
              }
            }
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    return {
      itemData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },

  methods: {
    tratarEventoClienteSelecionados(item) {
      this.itemData.cliente = item.id
    },
    tratarEventoDisciplinasSelecionadas(itens) {
      this.itemData.disciplinas = itens || {}
    },
    tratarEventoResponsaveisSelecionados(itens) {
      this.itemData.responsaveis = itens || {}
    },
    tratarEventoSelecionarFormato(item) {
      this.itemData.formato = item.id
    },
    tratarEventoTimesSelecionados(itens) {
      this.itemData.times = itens || {}
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
