import { computed, ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useFormatosList() {
  const toast = useToast()

  const refListTable = ref(null)

  const tableColumns = [
    {
      key: 'descricao',
      sortable: true,
      label: 'Nome',
    },

    {
      key: 'disciplinas',
      label: 'disciplinas',
    },
    {
      key: 'formato',
      label: 'formato',
    },
    {
      key: 'Participantes',
      sortable: false,
    },
    {
      key: 'Realizado',
      class: 'text-center',
    },
    {
      key: 'data_entrega',
      label: 'Entrega',
      sortable: true,
    },
    {
      key: 'Ações',
      class: 'text-center',
    },
  ]
  const perPage = ref(10)
  const totalItens = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const disciplinasFilter = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    currentPage.value - 1

    const localItemsCount = refListTable.value
      ? refListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalItens.value,
    }
  })

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, disciplinasFilter], () => {
    refetchData()
  })

  const fetchDados = (ctx, callback) => {
    store
      .dispatch('app-projetos/fetchDados', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        disciplinas: disciplinasFilter.value,
      })
      .then(response => {
        callback(response.data)
        totalItens.value = response.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Erro ao listar as projetos',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const update = item => {
    store.dispatch('app-projetos/update', item)
      .then(() => {
        refetchData()

        toast({
          component: ToastificationContent,
          props: {
            title: 'Projeto editado com sucesso',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
  }
  const deleteItem = id => {
    store.dispatch('app-projetos/deleteItem', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Projeto excluído com sucesso',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        refetchData()
      })
  }

  return {
    fetchDados,
    update,
    deleteItem,
    tableColumns,
    perPage,
    currentPage,
    totalItens,
    dataMeta,
    perPageOptions,
    searchQuery,
    disciplinasFilter,
    sortBy,
    isSortDirDesc,
    refListTable,
    refetchData,
  }
}
